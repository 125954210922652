import * as React from 'react';
import { FC } from 'react';
import { ImageGallery } from '../ui/imageGallery';

const Testimonial: FC<{
  data: any;
}> = ({ data }) => {
  return (
    <section
      className={`${data.classes} section testimonial-section section-testimonial has-backbround-gradient-grey`}
    >
      <div className="container column is-9 is-12-mobile  is-12-tablet">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered text-black">
              {data.title}
            </h2>
          </div>
        </div>
        <div className="columns is-centered set-flex">
          <div className="column set-image-right">
            <ImageGallery
              name={data.image_1}
              alt="we support you"
              className="top-image"
              width="480"
              height="340"
            />
            {data.image_2 && (
              <ImageGallery
                name={data.image_2}
                alt="we support you"
                className="bottom-image"
                width="280"
              />
            )}
          </div>
          <div className="column  set-quote-order">
            <div className="quote-warp">
              <p className="quote">"{data.quote}"</p>
              <p className="author">
                <b>{data.author}</b>, <br /> {data.location}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
