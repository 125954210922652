import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';
import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroVideo } from '../components/partials/common/hero';
import Features from '../components/sections/features';
import SectionFaq from '../components/sections/faq-section';
import Testimonial from '../components/sections/testimonial';
import KeyAccounts from '../components/sections/key-accounts';
import SectionColumns from '../components/sections/columns-section';
import ConversionSection from '../components/sections/conversion';
import RoiSection from '../components/sections/roi-section';
import Shop from '../components/partials/pricing/shop';

const Page: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { t } = useTranslation(localKey);
  const { lang, alternateUrls } = pageContext;

  const listbenefitsForYou: IColumnList = t('benefitsForYou.columns', {
    returnObjects: true,
  });
  const questions: IColumnList = t('faq.questions', {
    returnObjects: true,
  });
  const features: any = t('features', {
    returnObjects: true,
  });
  const testimonial_data: any = t('testimonial', {
    returnObjects: true,
  });
  let roi_industry = false;

  if (localKey === 'hair' || localKey === 'beauty') {
    roi_industry = true;
  }

  return (
    <Layout
      pageContext={pageContext}
      navClasses={!isMobileOnly && 'transparent'}
      mainClasses="overlay_nav industry"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroVideo translation={t} />
      <SectionColumns
        classes=""
        title={t('benefitsForYou.title')}
        columns={listbenefitsForYou}
      />
      {typeof testimonial_data === 'object' && (
        <Testimonial data={testimonial_data} />
      )}
      <KeyAccounts localKey={localKey} classes="has-background-grey " />
      <Features data={features} />
      {roi_industry &&
        lang !== 'fr' &&
        lang !== 'en-US' &&
        lang !== 'de-CH' && (
          <RoiSection lang={lang} classes="has-background-new-purple" />
        )}
      <SectionFaq
        classes={t('faq.classes')}
        title={t('faq.title')}
        questions={questions}
      />
      {lang !== 'en' &&
        lang !== 'fr' &&
        lang !== 'en-US' &&
        lang !== 'de-CH' &&
        lang !== 'es' && (
          <Shop
            data={t}
            title="Du möchtest dein Kassenzubehör <br> direkt online bestellen?"
          />
        )}
      <ConversionSection ctaUrl={t('conversion.ctaUrl')} />
    </Layout>
  );
};

export default Page;
